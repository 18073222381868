@font-face {
  font-family: yellowtail;
  src: url("./fonts/Yellowtail-Regular.ttf");
}

@font-face {
  font-family: opensans;
  src: url("./fonts/OpenSans.ttf");
}

@font-face {
  font-family: merriweather;
  src: url("./fonts/Merriweather-Regular.ttf");
}

* {
  font-family: opensans, monospace;
}

.mint-title {
  text-align: center;
  color: black;
  font-family: merriweather;
  font-size: 120px;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.mint-subtitle {
  text-align: center;
  color: black;
  font-family: yellowtail;
  font-size: 40px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: -60px;
}

body {
  height: 100vh;
  position: relative;
  background: white;
  background-image: url("./assets/degen_trips_bg.png");
  background-repeat: none;
  background-position: top;
  background-size: cover;
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.footer {
  text-align: center;
  color: white;
  font-family: yellowtail;
  font-size: xx-large;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;
  background: #7e02e6;
  padding-top: 25px;
  padding-bottom: 25px;
  text-shadow: 5px 3px black;
}

.wallet-container {
  padding-top: 20px;
  padding-bottom: 60px;
}

button.my-crossmint {
  margin-left: 20px;
  margin-right: 20px;
}

.crossmint-container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.candy-machine-container {
  position: relative;
  height: 100vh;
}

.candy-machine {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 768px) {

  .mint-title {
    font-size: 60px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .mint-subtitle {
    margin-bottom: -80px;
  }

  button.my-crossmint {
    font-size: 10px;
  }

  /* body {
    background-image: url("./assets/JetGetter_Mint_Day.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    margin: 0;
    padding: 0;
    height: 100%;
  } */
}
